import React from 'react';
import Box from '@material-ui/core/Box';
import SortBy from '../../ui/SortBy';

const columns = [
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>DEL</Box>
      <Box><SortBy name="del" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'del',
    Footer: <strong>Total</strong>
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>In WHS</Box>
      <Box><SortBy name="in_whs" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'in_whs',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + row.in_whs, 0)}</strong></Box>
    },
  },
  // {
  //   Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
  //     <Box>Outbound Scheduled</Box>
  //     <Box><SortBy name="in_outbound" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
  //   </Box>,
  //   accessor: 'in_outbound',
  //   Footer: (table) => {
  //     return <Box><strong>{table.data.reduce((acc, row) => acc + row.in_outbound, 0)}</strong></Box>
  //   }
  // },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Outbound Scheduled (Cargo loaded)</Box>
      <Box><SortBy name="cargo_loaded" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'cargo_loaded',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + row.cargo_loaded, 0)}</strong></Box>
    }
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Outbound Scheduled (Cargo not yet loaded)</Box>
      <Box><SortBy name="carge_not_loaded" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'carge_not_loaded',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + row.carge_not_loaded, 0)}</strong></Box>
    },
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Pending outbound</Box>
      <Box><SortBy name="pending_outbound" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'pending_outbound',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + row.pending_outbound, 0)}</strong></Box>
    }
  },

];

export default columns;
